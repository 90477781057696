.App {
  background-color: rgb(146, 146, 146);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main {
  background-color: white;
  display: flex;
  flex-direction: row;
  border: 1px solid rgb(192, 192, 192);
  border-radius: 10px;
  height: 100%;
  width: 100%;
}

/* SIDEBAR START */
.sidebar {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: rgb(44, 44, 44);
  width: 270px;
  justify-content: space-between;
  border-radius: 10px 0 0 10px;
}
.page {
  display: flex;
  flex-direction: column;
  h4 {
    color: #fff;
  }
}

.sidbar-btn {
  text-decoration: none;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 20px;
  font-weight: 400;
  border-radius: 5px;
  transition: ease-in 0.2s;
  /* background-color: #282c34; */
  color: #f0f0f0;
  padding: 5px 10px;
  margin-bottom: 5px;
}

.sidbar-btn:hover {
  background-color: #d4d4d4;
  color: #333;
  font-weight: 600;
}
.sidbar-btn--active {
  color: #333;
  background-color: #d4d4d4;
  font-weight: 600;
}
/* SIDEBAR END */
.rigth_w {
  background-color: rgb(218, 218, 218);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.licenseTable {
  background-color: white;
  width: 95%;
  height: 95%;
  border-radius: 10px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.table_option {
  display: flex;
  align-items: center;
  /* width: 90%; */
  height: 75px;
  justify-content: space-between;
  /* margin-top: 20px; */
}
.table_option_right {
  display: flex;
  justify-content: end;
  gap: 20px;
}
.option_right {
  display: flex;
  justify-content: end;
  height: 50px;
  /* margin-top: 20px; */
  /* width: 100%; */
  gap: 20px;
  align-items: center;
}

.search-input {
  background-color: #fafafa;
  border-radius: 8px;
  padding: 5px;
  padding-left: 10px;
  border: #acacac 2px solid;
}

.items_per_page {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.items_count {
  border: none;
}

.button_add {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35.6px;
  width: 35.6px;
  background-color: #62aae5;
  /* background-color: #007b7f; */
  border: none;
}
.btn_logo {
  width: 30px;
  height: 30px;
  color: white;
}

.button_add:hover {
  background-color: #4e82ad;
  /* background-color: #015f63; */
}
.delete_u {
  background-color: brown;
}
.delete_u:hover {
  background-color: rgb(189, 33, 33);
}

.table_body {
  overflow: auto;
  overflow-x: hidden;
  height: 100%;
  color: #333;
}
.table_body::-webkit-scrollbar {
  width: 6px; /* Ширина скроллбара */
}

.table_body::-webkit-scrollbar-thumb {
  background-color: #afafaf; /* Цвет ползунка скроллбара */
  border-radius: 6px; /* Скругление углов ползунка скроллбара */
}

.table_body::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Цвет фона скроллбара */
}
.btn_delete {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
}
.delete {
  color: rgb(170, 3, 3);
  align-self: center;
  height: 25px;
  width: 25px;
}
.delete:hover {
  color: rgb(230, 18, 18);
  cursor: pointer;
}
.pagination {
  margin-top: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  gap: 20px;
  button {
    display: flex;
    align-items: center;
    height: 30px;
    border-radius: 100%;
    /* color: ; */
    border: #9e9e9e solid 0.5px;
  }
  span {
    cursor: pointer;
  }
  .active {
    color: #007b7f;
  }
}
.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.card {
  padding: 10px 20px;
  cursor: default;
  border-radius: 10px;
  a,
  p {
    text-decoration: none;
    color: black;
    font-weight: 600;
  }
  .value {
    margin-left: 20px;
    font-weight: 400;
    width: 120px;
  }
}

.table_body {
  overflow: auto;
  overflow-x: hidden;
  height: 82%;
  color: #333;
  margin: 0;
}
.popcard {
  background-color: #333;
  color: white;
  display: flex;
  height: 30px;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}
.cardDetail {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cardInfo {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  gap: 5px;
}

.detail {
  /* background-color: #acacac; */
  height: 100%;
  border-collapse: collapse;
  td {
    position: relative;
    width: 100px;
  }

  .input_editable {
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 250px;
  }

  .button_edit {
    background-color: #4caf50;
    color: #fff;
    width: 25px;
    height: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 30px;
  }

  .button_edit:hover {
    background-color: #45a049;
  }
}
.dright {
  tbody {
    display: block;
    height: 90%;
    overflow-y: auto;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
  }
}

.dright::-webkit-scrollbar {
  width: 6px; /* Ширина скроллбара */
}

.dright::-webkit-scrollbar-thumb {
  background-color: #afafaf; /* Цвет ползунка скроллбара */
  border-radius: 6px; /* Скругление углов ползунка скроллбара */
}

.dright::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Цвет фона скроллбара */
}

.modall {
  width: 100%;
  background-color: #333333;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 50px;
  }
  label {
    text-align: start;
  }
  input {
    border-radius: 4px;
    width: 230px;
  }
  button {
    background-color: #4caf50;
    color: white;
    font-weight: 600;
    border-radius: 4px;
    padding: 5px 10px;
  }
}
.closeB {
  cursor: pointer;
}
.addUser {
  form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 10px;
  }
  button,
  h4 {
    grid-column: span 2; /* Растягиваем кнопку на 2 колонки */
  }
}

.gen_key {
  margin: 0;
  padding: 0;
  margin-left: 5px;
  position: absolute;
  background-color: transparent;
}
.arka {
  button {
    padding: 0;
    background-color: transparent;
    border: none;
  }
  .sumbit {
    background-color: #4caf50;
    color: white;
    font-weight: 600;
    border-radius: 4px;
    padding: 5px 10px;
  }
}

/* BUTTON FOR CHANGE STATUS */
.statusChangeBtn {
  border: #333 solid 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  border-radius: 10px;
  font-weight: 600;
  padding: 10px;
  color: white;
  cursor: pointer;
}
.ActiveSBtn {
  background-color: #007f55;
}
.ActiveSBtn:hover {
  background-color: #015035;
}
.DeactiveSBtn {
  background-color: brown;
}
.DeactiveSBtn:hover {
  background-color: rgb(117, 29, 29);
}
.user_details_table {
  td {
    vertical-align: top;
  }
}

.custom-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  padding: 12px 16px;
  display: block;
  text-decoration: none;
  color: #333;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.custom-dropdown:hover .dropdown-content {
  display: block;
}

.logo-link {
  text-decoration: none;
}

.statistic_header {
  text-align: center;
  padding-top: 8px;
}

.statistic_wrapper {
  height: 60%;
  width: 95%;
  background-color: white;
  border-radius: 10px;
}

.statistic_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
}

.statistic_card_button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 10px;
  background-color: #007f55;
  color: white;
  font-weight: 600;
  cursor: pointer;
  padding: 0 14px 0 14px;
  width: 100%;
}

.statistic_card_button:hover {
  background-color: #015035;
}

.statistic_card_p_left {
  font-weight: 600;
}

.statistic_hr {
  /* border: 1px solid #f1f1f1; */
  margin: 0 20px 0 20px;
}

.statistic_clients_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
}

.main-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.server-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
}

.server-card h2 {
  color: #333;
}

.server-card p {
  margin: 8px 0;
}
